import React from "react";

function Infrastructure() {
  return (
    <div className="infra">
      <h3>Infrastructure Page</h3>
    </div>
  );
}
export default Infrastructure;
