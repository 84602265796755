import React from "react";
import "../CSS/Footer.css";
import { Link } from "react-router-dom";
// import { FaLinkedin } from "react-icons/fa";
// import { AiFillGoogleSquare } from "react-icons/ai";

function Footer() {
  return (
    <div className="footer-main">
      <div className="footer-top-flex">
        <div className=" footer-flex">
          <div className="footer-left">
            <h3 className="footer-title">About Cotton Trends</h3>
            <p className="footer-left-content">
              At Cotton Trends, we believe in maintaining Quality with Ethics.
              We value and trust our customers' value, and we attempt to uphold
              it by providing products of unrivalled quality, brought possible
              by sophisticated manufacturing facilities and rigorous checking
              and testing processes at all stages of production.
            </p>
          </div>
          <div className="footer-second">
            <h3 className="footer-title">Address</h3>
            <p>Cotton Trends</p>
            <p>19/55, 80 Feet Road</p>
            <p>Karur - 639 002</p>
            <p>TamilNadu</p>
            <p>India</p>
          </div>
          <div className="footer-third">
            <h3 className="footer-title">Quick Links</h3>
            <ul>
              <li>
                <Link className="footer-link" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="footer-link" to="/about">
                  About Us
                </Link>
              </li>
              <li>
                <Link className="footer-link" to="/certifications">
                  Certifications
                </Link>
              </li>
              <li>
                <Link className="footer-link" to="/products">
                  Products
                </Link>
              </li>

              <li>
                <Link className="footer-link" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-right">
            <h3 className="footer-title">Location</h3>
            <div className="map-footer">
              <iframe
                width="350"
                height="170"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=global impex, karur&t=&z=10&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className=" footer-bottom-flex">
        <div className="container footer-btm-flex">
          <div>
            <p className="footer-copyright">
              @2022 Cotton Trends. All Rights Reserved.
              <span>
                <a href="http://interdeccaan.in/" target="_blank">
                  Designed by Inter Deccaan Solutions
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
