import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Infrastructure from "./Pages/Infrastructure";
import Certifications from "./Pages/Certfications";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
import Products from "./Pages/Products";
import Apron from "./Pages/Apron";
import Cushion from "./Pages/Cushion";
import Curtain from "./Pages/Curtain";
import Throws from "./Pages/Throws";
import TableCloth from "./Pages/TableCloth";
import Napkins from "./Pages/Napkins";
import Runner from "./Pages/Runner";
import Placemat from "./Pages/Placemat";
import BeachTowel from "./Pages/BeachTowel";
import LaundryBags from "./Pages/LaundryBags";
import KitchenTowel from "./Pages/KitchenTowel";

function Main() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/infrastructure" element={<Infrastructure />} />
          <Route path="/products" element={<Products />} />
          <Route path="/apron" element={<Apron />} />
          <Route path="/cushion" element={<Cushion />} />
          <Route path="/curtain" element={<Curtain />} />
          <Route path="/throws" element={<Throws />} />
          <Route path="/tablecloth" element={<TableCloth />} />
          <Route path="/napkins" element={<Napkins />} />
          <Route path="/runner" element={<Runner />} />
          <Route path="/placemat" element={<Placemat />} />
          <Route path="/beachtowel" element={<BeachTowel />} />
          <Route path="/laundrybags" element={<LaundryBags />} />
          <Route path="/kitchentowel" element={<KitchenTowel />} />


          <Route path="/certifications" element={<Certifications />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}
export default Main;
