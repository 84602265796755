import React from "react";
import "../CSS/About.css";
import about from "../Assets/about.jpg";
function About() {
  window.scrollTo(0, 0);

  return (
    <div className="about-main">
      <div className="about-head">
        <img
          src={
            "https://www.impactbnd.com/hubfs/blog-image-uploads/best-about-us-pages.jpg"
          }
        ></img>
      </div>
      <div className="container about-body">
        <div className="about-body-top">
          <h3 className="about-title">About Us</h3>
          <p className="about-content">
            Cotton Trends, located in Karur, India, is a home textile
            manufacturer. We manufacture and ship home furnishings and infant
            fabrics for reputable multi-national stores all over the world,
            primarily in Australia, the United States, Germany, Canada, and
            South Africa. Table linen and kitchen linen, bedding articles,
            external articles such as beach and box mattresses, and baby
            textiles, as well as items such as curtains and grocery bags, are
            all offered in Home Textiles. All of our refined and long-lasting
            goods are made with top-quality yarn purchased from
            industry-standard approved factories. We can produce 10 million
            items per year.
          </p>
        </div>
        {/* Vision Mission Section */}
        <div className="vision-section">
          <div className="vision-left">
            <img src="https://t3.ftcdn.net/jpg/02/39/52/06/360_F_239520607_abB3AakIrZozIAPgdVAMiMArLwi0uJTL.jpg"></img>
          </div>
          <div className="vision-right">
            <h3 className="vision-title">Vision</h3>
            <p className="about-content">
              Our vision assures competitive pricing, consistent on-time
              deliveries, impeccable product quality, excellence in customer
              focus, engagement and satisfaction, devotion to innovative
              thinking and innovation, and empowered employees. Furthermore, it
              calls for world order, perfect professional and highest ethical
              standards.
            </p>
          </div>
        </div>
        {/* Mission Section */}
        <div className="vision-section">
          <div className="vision-right">
            <h3 className="vision-title">Mission</h3>
            <p className="about-content">
              Cotton Trends is dedicated to consistent improvement in the goal
              of providing products and services to buyers through on-time
              delivery and improving client satisfaction through quality and
              value." Cotton Trends pursues a high level of performance and aims
              to maintain a long-term leadership position in the home textile
              market segments.
            </p>
          </div>
          <div className="vision-left">
            <img src="https://careernursing.org/uploads/Mission.png"></img>
          </div>
        </div>
        {/* Infrastructure Section */}
        <h3 className="infra-title">Infrastructure</h3>
        <div className="infra-main">
          <p className="infra-content">
            Factory spread over 25000 Sq.ft with Imported barudan sewing
            machines and own dyeing & loom to meet on time delivery. Global
            impox combines heritage and creativity. We use the most modern
            hi-tech automated looms for perfect quality and hand-weaving for a
            personal touch. To make our products feel as beautiful as they look,
            we use 100% pure cotton yarn and, when required, 100% organic cotton
            yarn.
          </p>
        </div>
      </div>
    </div>
  );
}
export default About;
