import React, { useRef, useEffect } from "react";
import "../CSS/Home.css";
import Carousel from "react-bootstrap/Carousel";
import flag1 from "../Assets/flag1.png";
import flag2 from "../Assets/flag2.jfif";
import flag3 from "../Assets/flag3.png";
import flag4 from "../Assets/flag4.png";
import flag5 from "../Assets/flag5.jfif";

import apron from "../Assets/Apron/6.jpg";
import cushion from "../Assets/Cushion/6.jpg";
import curtain from "../Assets/Curtain/6.jpg";
import throws from "../Assets/Throws/1.jpg";
import tablecloth from "../Assets/TableCloth/6.jpg";
import napkin from "../Assets/Napkins/4.jpg";
import runner from "../Assets/Runner/7.jpg";
import placemat from "../Assets/Placemat/9.webp";
import beachcloth from "../Assets/BeachTowel/1.jpg";
import laundrybag from "../Assets/LaundryBags/1.jpg";
import kitchentowel from "../Assets/KitchenTowel/1.jpg";
import img1 from "../Assets/home/img1.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Home() {
  window.scrollTo(0, 0);
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const interval = setInterval(() => {
      slider.slickNext();
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home-main">
      <div className="home-head">
        <Carousel fade interval={2000} pause={false}>
          <Carousel.Item>
            <img
              className="d-block w-100 slide-img"
              src="https://img.freepik.com/free-photo/person-working-embroidery-workshop_23-2148970775.jpg?w=1380&t=st=1682672291~exp=1682672891~hmac=0f2190f7b6cd7b3e087769c3df94ada6bf6efa2990c2155ee1e467c6fa4cdec1"
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 slide-img"
              src="https://img.freepik.com/free-photo/stack-fabric-with-word-s-it_1340-24022.jpg?w=1380&t=st=1682672264~exp=1682672864~hmac=dbb3af5804af6fa6e05b31096e7936bf3d5e4bbaac1e91863d05d6928eadceae"
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 slide-img"
              src={
                "https://img.freepik.com/free-photo/close-up-woman-holding-patterns_23-2148903486.jpg?w=1380&t=st=1682672330~exp=1682672930~hmac=fea8296959be5c1e04d2d1bbe67965819fb662c8468aaaafb4eaa210b83d1789"
              }
              alt="First slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="country-main">
        <div className="home-top-intro">
          <h3>Cotton Trends</h3>
          <p>
            At Cotton Trends, we believe in maintaining Quality with Ethics. We
            value and trust our customers' value, and we attempt to uphold it by
            providing products of unrivalled quality, brought possible by
            sophisticated manufacturing facilities and rigorous checking and
            testing processes at all stages of production.We work together with
            you to create an unique design which reflects your style and meets
            your requirements. We recognise that our clients' requirements may
            alter, and we are always ready to be malleable and flexible. Our
            team of creators and experienced workers will make sure to it that
            your concept is achieved.
          </p>
        </div>
        <h3 className="country-title">Countries Serving</h3>
        <div className="country-flex">
          <Slider
            ref={sliderRef}
            infinite={true}
            speed={1000}
            slidesToShow={4}
            slidesToScroll={1}
          >
            <div className="country-card">
              <img src={flag1} alt="Flag 1" />
            </div>
            <div className="country-card">
              <img src={flag2} alt="Flag 2" />
            </div>
            <div className="country-card">
              <img src={flag3} alt="Flag 3" />
            </div>
            <div className="country-card">
              <img src={flag4} alt="Flag 4" />
            </div>
            <div className="country-card">
              <img src={flag5} alt="Flag 5" />
            </div>
          </Slider>
        </div>
      </div>

      {/* Product Ranges */}
      <div className="product-body-section">
        <h3 className="product-title">Product Categories</h3>
        <div className="product-list-flex">
          <div className="product-main">
            <img src={apron} />
            <div className="product-caption">
              <h3>Apron</h3>
            </div>
          </div>
          <div className="product-main">
            <img src={cushion} />
            <div className="product-caption">
              <h3>Cushion</h3>
            </div>
          </div>
          <div className="product-main">
            <img src={curtain} />
            <div className="product-caption">
              <h3>Curtain</h3>
            </div>
          </div>
        </div>

        <div className="product-list-flex">
          <div className="product-main">
            <img src={throws} />
            <div className="product-caption">
              <h3>Throws</h3>
            </div>
          </div>
          <div className="product-main">
            <img src={tablecloth} />
            <div className="product-caption">
              <h3>Table Cloths</h3>
            </div>
          </div>
          <div className="product-main">
            <img src={napkin} />
            <div className="product-caption">
              <h3>Napkins</h3>
            </div>
          </div>
        </div>

        <div className="product-list-flex">
          <div className="product-main">
            <img src={runner} />
            <div className="product-caption">
              <h3>Runner</h3>
            </div>
          </div>
          <div className="product-main">
            <img src={placemat} />
            <div className="product-caption">
              <h3>Placemat</h3>
            </div>
          </div>
          <div className="product-main">
            <img src={beachcloth} />
            <div className="product-caption">
              <h3>Beach Towel</h3>
            </div>
          </div>
        </div>

        <div className="product-list-flex">
          <div className="product-main">
            <img src={laundrybag} />
            <div className="product-caption">
              <h3>Laundry Bags</h3>
            </div>
          </div>
          <div className="product-main">
            <img src={kitchentowel} />
            <div className="product-caption">
              <h3>Kitchen Towel</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
