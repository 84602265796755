import React from "react";
import "../CSS/Contact.css";
import { HiLocationMarker } from "react-icons/hi";
import { AiFillMail } from "react-icons/ai";
import { GoDeviceMobile } from "react-icons/go";

function Contact() {
  window.scrollTo(0, 0);

  return (
    <div className="container contact-main">
      <div className="contact-flex">
        <div className="contact-left">
          <h3 className="contact-title">For Enquiry</h3>
          <form className="contact-form">
            <div className="contact-input">
              <input type="text" placeholder="First Name" />
            </div>
            <div className="contact-input">
              <input type="text" placeholder="Last Name" />
            </div>
            <div className="contact-input">
              <input type="email" placeholder="Your Mail" />
            </div>
            <div className="contact-input">
              <input type="number" placeholder="Mobile Number" />
            </div>
            <div className="contact-message">
              <input type="text" placeholder="Your Message" />
            </div>
            <div className="contact-btn">
              <button>Submit</button>
            </div>
          </form>
        </div>
        <div className="contact-right">
          <div>
            <h3 className="contact-title">To Contact</h3>
            <h3 className="contact-sub-title">Address</h3>
            <div className="contact-right-div">
              <div>
                <HiLocationMarker className="contact-icon" />
              </div>
              <div>
                <p className="contact-right-content">Cotton Trends</p>
                <p className="contact-right-content">19/55, 80 Feet Road</p>
                <p className="contact-right-content">Karur - 639 002</p>
              </div>
            </div>
            <h3 className="contact-sub-title">Mobile</h3>
            <div className="contact-right-div">
              <div>
                <GoDeviceMobile className="contact-icon" />
              </div>
              <div>
                <p className="contact-right-content">+ 91 - 98940 38656</p>
                <p className="contact-right-content">+ 91 – 4324 233656</p>
              </div>
            </div>
            <h3 className="contact-sub-title">E - Mail</h3>
            <div className="contact-right-div">
              <div>
                <AiFillMail className="contact-icon" />
              </div>
              <div>
                <p className="contact-right-content">sales@cottontrends.in</p>
                <p className="contact-right-content">senthil@cottontrends.in</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
