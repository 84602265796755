import React from "react";
import "../CSS/ProductSub.css";
import product1 from "../Assets/Runner/1.jpg";
import product2 from "../Assets/Runner/2.jpg";
import product3 from "../Assets/Runner/3.jpg";
import product4 from "../Assets/Runner/4.jpg";
import product5 from "../Assets/Runner/8.webp";
import product6 from "../Assets/Runner/6.jpg";

function Runner() {
  window.scrollTo(0, 0);

  return (
    <div className="product-sub-main">
      <div className="product-sub-head">
        <h3>Runner</h3>
      </div>
      <div className="product-sub-body">
        <div className="product-sub-flex">
          <img src={product6}></img>
          <img src={product2}></img>
          <img src={product3}></img>
        </div>
        <div className="product-sub-flex">
          <img src={product4}></img>

          <img src={product1}></img>
          <img src={product5}></img>
        </div>
      </div>
    </div>
  );
}
export default Runner;
