import React, { useState } from "react";
import "../CSS/Navbar.css";
import { Link } from "react-router-dom";
// import logo from "../Assets/logo.jpeg";
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navLinks = document.querySelectorAll(".nav-items > a");

  navLinks.forEach((link) => {
    link.addEventListener("click", () => {
      const navMenu = document.querySelector(".nav-items");
      navMenu.classList.remove("open");
      setIsOpen(false);
    });
  });

  return (
    <div className="Navbar">
      <div className="container nav-top-flex">
        {/* <div className="nav-img-div">
          <h3 className="company-name">Cotton Trends</h3>
        </div> */}
        <div className={`nav-items ${isOpen && "open"}`}>
          <Link to="/">Home</Link>
          <Link to="/about">About Us</Link>
          <Link to="/certifications">Certifications</Link>
          <Link to="/products">Products</Link>
          <Link to="/contact">Contact Us</Link>
        </div>
      </div>
      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </div>
  );
}
export default Navbar;
