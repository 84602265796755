import React from "react";
import "../CSS/ProductSub.css";
import product1 from "../Assets/Curtain/1.jpg";
import product2 from "../Assets/Curtain/2.jpg";
import product3 from "../Assets/Curtain/3.jpg";
import product4 from "../Assets/Curtain/4.jpg";
import product5 from "../Assets/Curtain/5.jpg";
import product6 from "../Assets/Curtain/6.jpg";

function Curtain() {
  window.scrollTo(0, 0);

  return (
    <div className="product-sub-main">
      <div className="product-sub-head">
        <h3>Curtain</h3>
      </div>
      <div className="product-sub-body">
        <div className="product-sub-flex">
          <img src={product1}></img>
          <img src={product2}></img>
          <img src={product6}></img>
        </div>
        <div className="product-sub-flex">
          <img src={product4}></img>
          <img src={product5}></img>
          <img src={product3}></img>
        </div>
      </div>
    </div>
  );
}
export default Curtain;
